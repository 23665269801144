import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// import flex layout
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material.module';

import { NgxSliderModule } from '@angular-slider/ngx-slider';

// import leaflet
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { MapComponent } from './map/map.component';

// import auth stuff
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NavbarComponent } from './navbar/navbar.component';
import { FilterComponent } from './filter/filter.component';
import { ListComponent } from './list/list.component';
import { FitzoomComponent } from './map/controls/fitzoom/fitzoom.component';
import { LegendComponent } from './map/controls/legend/legend.component';
import { WebStorageModule } from 'ngx-store';
import { FilterListComponent } from './filter-list/filter-list.component';
import { SuburbSearchComponent } from './map/controls/suburb-search/suburb-search.component';
import { ProviderDialogComponent } from './map/provider-dialog/provider-dialog.component';
import { OnboardPopupComponent } from './popups/onboard-popup/onboard-popup.component';
import { ObjSortPipe } from './obj-sort.pipe';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { ExtractPhoneNumPipe } from './extract-phone-num.pipe';
import { ListPopupComponent } from './popups/list-popup/list-popup.component';
import { SurveyPopupComponent } from './popups/survey-popup/survey-popup.component';
import { FormatLinkPipePipe } from './format-link-pipe.pipe';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
const pub = environment.public;

let imps = [
  BrowserModule,
  AppRoutingModule,
  HttpClientModule,
  ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
  FlexLayoutModule,
  MaterialModule,
  LeafletModule,
  LeafletMarkerClusterModule,
  BrowserAnimationsModule,
  FormsModule,
  ReactiveFormsModule,
  NgxSliderModule,
  NgxGoogleAnalyticsModule.forRoot(environment.ga),
  WebStorageModule.forRoot(),
  MsalModule.forRoot({
    auth: {
      clientId: environment.clientId, // This is your client ID
      authority: environment.authority, // This is your tenant ID
      redirectUri: environment.redirectUrl// This is your redirect URI
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
  },
  {
    popUp: !isIE,
    consentScopes: [
      'user.read',
      'openid',
      'profile',
    ],
    protectedResourceMap: [
      ['https://graph.microsoft.com/v1.0/me', ['user.read']]
    ],
    extraQueryParameters: {}
  })
];
let provs = [];

if (!pub) {

  provs.push(
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }
  )

}

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    NavbarComponent,
    FilterComponent,
    ListComponent,
    FitzoomComponent,
    LegendComponent,
    FilterListComponent,
    SuburbSearchComponent,
    ProviderDialogComponent,
    OnboardPopupComponent,
    ObjSortPipe,
    ExtractPhoneNumPipe,
    ListPopupComponent,
    SurveyPopupComponent,
    FormatLinkPipePipe
  ],
  imports: imps,
  providers: provs,
  bootstrap: [AppComponent]
})
export class AppModule { }
