<div    
    #map
    class="map"
     leaflet 
     (leafletMapReady)="onMapReady($event)"
     [leafletOptions]="options"
     [leafletMarkerCluster]="providerLayer"
     [leafletMarkerClusterOptions]="clusterOptions"
     (leafletMarkerClusterReady)="onMarkerClusterReady($event)"
     [leafletLayers]="[ localityLayer ]">
     <div>
            <mat-progress-spinner *ngIf="providersLoading" mode="indeterminate"></mat-progress-spinner>
            <div class="top right filter-map-controls">
                <!-- <app-filter class="control" id="langs" [filterParam]="'langs'" [index]="true"></app-filter>
                <app-filter class="control" id="schooltype" [filterParam]="'schooltype'" [index]="true"></app-filter> -->
                <app-suburb-search></app-suburb-search>
            </div>
            <div class="top left">
                <!-- <app-geocoder (update)="newGeocode($event)" [clickEv]="mapClick"></app-geocoder> -->
            </div>
            <div class="bottom left">
                <app-fitzoom (fit)="fitLayers()"></app-fitzoom>
            </div>
            <div class="bottom right">
                <app-legend></app-legend>
            </div>
            <!-- <div fxLayout 
                fxLayoutAlign="center center"
                class="top center-container">
                <app-filter-list 
                    [schoolCount]="schoolCount"
                    fxFlex="30%"
                    fxFlex.lt-lg="80%"></app-filter-list>
            </div> -->
            
            <!-- <app-locator (locate)="locateUser()"></app-locator> -->
        </div>
</div>