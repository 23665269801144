import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatLinkPipe'
})
export class FormatLinkPipePipe implements PipeTransform {

  /**
   * Find URLs and replace them with <a> elements that link to the URL
   * @param value text value with or without URLs in it
   * @param args Other args - not used
   * @returns 
   */
  transform(value: string, ...args: unknown[]): unknown {
    if (typeof value == 'string') {
      
      // regex to get urls
      const urlRegex = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/igm;

      // get matches
      const urlMatchList = Array.from(value.matchAll(urlRegex), m => m[0]);

      

      // format each url into an <a>
      urlMatchList.forEach((item: string) => {
        let newItem = item.substring(0, 3) == 'www' ? 'https://' + item : item;
        let format = `<a href="${newItem.trim()}" target="blank">${newItem.trim()}</a>`
        value = value.replace(item, format);
      })

    }

    return value;
  }

}
