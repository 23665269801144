import { Injectable } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, map, tap, share } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import buffer from '@turf/buffer';

import { SessionService } from './session.service'
import { Feature } from 'geojson';

@Injectable({
  providedIn: 'root'
})
export class LocalitiesService {

  // json url
  private resourceURL: string = '../data/localities.json';

  // session key
  private sessionKey: string = 'LOCALITIES';

  // make observables
  private localities!: Observable<any>;

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation: string = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  
  // fetch localities from session
  private _fetchLocalities(): Observable<any> {

    // get schools from session using key
    return this.session.get(this.sessionKey).pipe(
      // log error
      catchError(this.handleError('_fetchLocalities', []))
    );
  }

  // load localities directly from JSON
  private _loadLocalities(): Observable<any> {

    if (this.localities) {
      return this.localities.pipe(
      )
    }

    // get localities json
    this.localities = this.http.get<any>(this.resourceURL).pipe(
      share(),
      // log on loaded event, store received to session
      tap((localities: any) => {

        // save loaded providers data to session
        this.session.set(this.sessionKey, localities);

      }),

      // log error
      catchError(this.handleError('_loadLocalities', []))
    )

    return this.localities
  }

  // get Providers using params
  private _getLocalities(): Observable<any> {

    // if key exists in session, load it
    if (this.session.isSet(this.sessionKey)) {
      return this._fetchLocalities();
    // otherwise load the json
    } else {
      return this._loadLocalities();
    }

  }
  
  public getLocalityList(): Observable<any[]> {
    return this._getLocalities().pipe(
      map((locs: any) => {
        let arr: any[] = [];
        for (let [key, loc] of Object.entries(locs)) {
          arr.push({
            id: key,
            // @ts-ignore
            label: loc.label
          })
        }
        return arr
      })
    )
  }

  private _loc2geojson(loc: any): any {
    let gs = {
      type: "Feature",
      geometry: loc.geom,
      properties: loc
    }

    return gs
  }

  // clone object
  public clone(obj: any): any {

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        // copy date value
        let copy: Date = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        let copy: any[] = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = this.clone(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      let copy: Object = {};
      for (var attr in obj) {
            //@ts-ignore
            if (obj.hasOwnProperty(attr)) copy[attr] = this.clone(obj[attr]);
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  private _bufferAndSetFlag(gj: Feature, buff: number): Feature {
    let aa = buffer(gj, buff, { units: 'kilometers' })
    //@ts-ignore
    aa.properties['buffer'] = true
    return aa
  }

  public getBufferedLocalityById(id: number | string, buff: number | string = 0, justBuffer: boolean = true): Observable<any> {
    return this._getLocalities().pipe(
      //@ts-ignore
      map((locs: any) => {
        for (let [key, loc] of Object.entries(locs)) {
          if (id == key) {
            //@ts-ignore
            if (justBuffer || (!justBuffer && parseFloat(buff) <= 0)) {
              //@ts-ignore
              return buffer(this._loc2geojson(loc), buff, { units: 'kilometers' })
            }
            else {

              let loc2 = this.clone(loc)

              return [
                this._loc2geojson(loc),
                this._bufferAndSetFlag(this._loc2geojson(loc2), buff as number)
              ]
            }
          }
        }
      })
    )
  }

  constructor(
    private http: HttpClient,
    private session: SessionService,
  ) {}
}
