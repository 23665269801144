

<h1 mat-dialog-title>
    
    Abortion & Contraception Services
</h1>
<mat-dialog-content fxLayout="column" class="mat-typography" >
    <!-- <img class="fit-logo" src="../../assets/CbyCLogo_purpleandgreen_square.png" /> -->
    <mat-tab-group [(selectedIndex)]="selectedIndex" dynamicHeight mat-align-tabs="center">
        <mat-tab #locTab aria-label="Search by location" label="Location" cdkScrollable>
            <div fxFlex>
                <h2>Are you looking for services in a particular area?</h2>
                <app-suburb-search [mode]="'inline'"></app-suburb-search>
                
            </div>
            
        </mat-tab>
        <mat-tab #servicesTab aria-label="Filter by services" label="Services" cdkScrollable>
            <app-filter [ngClass]="'override-position'"></app-filter>
        </mat-tab>
    </mat-tab-group>
    <div class="tab-nav" fxLayout="row" fxLayoutAlign="start center">
        <button fxFlex mat-stroked-button (click)="selectedIndex = 0" [disabled]="selectedIndex < 1">Back</button>
        <button fxFlex mat-stroked-button (click)="selectedIndex = 1" [disabled]="selectedIndex > 0">Next</button>
    </div>
    <div>
        <button fxFlex mat-stroked-button (click)="closeDialog()">Search</button>
    </div>
</mat-dialog-content>
