import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, ParamMap, Params } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, delay } from 'rxjs/operators';

import { ConfigService } from '../services/config.service';
import { ProvidersService } from '../services/providers.service';
import { LocalitiesService } from '../services/localities.service';
import { PsAppConfig, PsFilterConfig } from '../definitions/config';

import { ListPopupComponent } from '../popups/list-popup/list-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.scss']
})
export class FilterListComponent implements OnInit, OnDestroy {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private configService: ConfigService,
    private providersService: ProvidersService,
    private localitiesService: LocalitiesService,
    public onboardPopupDialog: MatDialog,
  ) { }

  // manage unsubscriptions
  private readonly _ngUnsubscribe: Subject<any> = new Subject();

  public filterConfig!: PsFilterConfig;
  public filterObj: Params = {};
  public chipList: any[] = [];
  public providerCount: number = 0;

  public openListDialog(): void {
    this.onboardPopupDialog.open(ListPopupComponent);

  }

  // clone object
  public clone(obj: any): any {

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        // copy date value
        let copy: Date = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        let copy: any[] = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = this.clone(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      let copy: Object = {};
      for (var attr in obj) {
            //@ts-ignore
            if (obj.hasOwnProperty(attr)) copy[attr] = this.clone(obj[attr]);
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  private _formatChipList(filters: Params) {

    // loop list of filters
    for (let filter of Object.entries(filters)) {

      // if boolean filter
      if (filter[1] == 'true') {
        this.chipList.push(
          {
            // get label for code
            label: this._getLabel(filter[0]),
            // store code as code
            code: filter[0],
            // no group for single filters
            group: null
          })
      }
      // if non boolean fitler (i.e. group filter)
      else if (filter[1] != 'true' && filter[0] != 'gestation') {

        // test if array
        if (Array.isArray(filter[1])) {
          // loop through group list
          filter[1].forEach((ent: any) => {
            this.chipList.push(
              {
                // get label for group and value
                label: this._getLabel(ent, filter[0]),
                // code is value
                code: ent,
                // group is group
                group: filter[0]
              })
          })
        } else if (filter[0] == 'loc' || filter[0] == 'radius') {
          
          this.chipList.push(
            {
              //@ts-ignore
              label: this._getLabel(filter[1], filter[0]),
              code: filter[1],
              group: filter[0]
            })
        } else {
          this.chipList.push(
            {
              //@ts-ignore
              label: this._getLabel(filter[1], filter[0]),
              code: filter[1],
              group: filter[0]
            })
        }

      } else if (filter[0] == 'gestation') {
        this.chipList.push(
          {
            label: `Gestation: ${filter[1].replace(',', ' to ')} weeks`,
            code: filter[0],
            group: filter[0]
          })
      }
    }

  }

  // initiate + subscribe to getFilterConfig on service
  private _getFilterConfig(): void {

    this.configService.getConfig()
      .subscribe((items: PsAppConfig) => {
        
        this.filterConfig = items.filterElements;

      });
    
  } 

  private _getLabel(code: string, group: string | null = null) {

    // if no group specified, split group from code and get value
    if (group === null) {

      let g = code.split('_')[0]

      return this.filterConfig[g].entries[code].list_label || 'loading...'

      // otherwise use the specified group
    } else if (this.filterConfig && this.filterConfig.hasOwnProperty(group)) {

      return this.filterConfig[group].entries[code].list_label
      // custom behaviour for 
    } else if (group === 'radius') {
      return code + ' km'
    } else {
      let label = 'loading...';
      this.localitiesService.getBufferedLocalityById(code)
        .pipe(takeUntil(this._ngUnsubscribe))
        .subscribe({
        next: (val: any) => {
          label = val.properties.label
        }
      })
      return label
    }

  }

  public removeChip(item: any) {

    // hold params
    let qParams: Params = {};

    // loop items in filter obj
    for (let [key, value] of Object.entries(this.filterObj)) {

      // check for code-only values
      if (item.code === key) delete this.filterObj[key]

      // check for group values
      // if group and array, then it is a list
      //@ts-ignore
      if (item.group === key && Array.isArray(value) && value.indexOf(item.code) > -1) this.filterObj[key].splice(value.indexOf(item.code), 1)
      // otherwise filter is single string and whole filter should be removed
      //@ts-ignore
      else if (item.group === key && value.indexOf(item.code) > -1) delete this.filterObj[key]

      if (key === 'loc' && this.filterObj.hasOwnProperty('radius')) delete this.filterObj.radius;

    }

    // save params
    qParams = this.filterObj;

    // send params to router
    this.router.navigate(['/'], {
      queryParams: qParams,
      // queryParamsHandling: 'merge',
      preserveFragment: true
    }).catch((err) => console.error(`Navigation error - ${err}`));

  }

  public clearFilters(): void {

    // hold params
    let qParams: Params = {};

    // send params to router
    this.router.navigate(['/'], {
      queryParams: qParams,
      // queryParamsHandling: 'merge',
      preserveFragment: true
    }).catch((err) => console.error(`Navigation error - ${err}`));

  }

  ngOnInit(): void {

    console.debug('filter list init');

    // grab provider count from service
    this.providersService.getProviderCount()
      .pipe(
        delay(0),
        takeUntil(this._ngUnsubscribe)
      )
      .subscribe({
        next: (val: number) => {
          this.providerCount = val;
      }
    });

    // observe route parameters for filtering
    this.route.queryParamMap
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe(
      {
          next: (params: Params) => {
          
          this.configService.getConfig()
            .subscribe((items: PsAppConfig) => {

              // empty chip list
              this.chipList = [];
              
              this.filterConfig = items.filterElements;

              // load params (if they exist)
              this.filterObj = Object.keys(params["params"]).length === 0 ? {} : this.clone(params["params"]);

              this._formatChipList(this.filterObj);

            });

          
          
          }
      }
    )

  }

  // handle unsubscriptions
  ngOnDestroy() {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

}
