import { Component, OnInit, OnDestroy, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, ParamMap, Params } from '@angular/router';

import { MatInput } from '@angular/material/input';
import {ErrorStateMatcher} from '@angular/material/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import Fuse from 'fuse.js';

import { DomEvent } from 'leaflet';

import { LocalitiesService } from './../../../services/localities.service'

import { MediaObserver } from '@angular/flex-layout';

import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-suburb-search',
  templateUrl: './suburb-search.component.html',
  styleUrls: ['./suburb-search.component.scss']
})
export class SuburbSearchComponent implements OnInit, OnDestroy {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localitiesService: LocalitiesService,
    private ref: ElementRef,
    private mediaObserver: MediaObserver,
    private $gaService: GoogleAnalyticsService,
  ) { }

  // manage unsubscriptions
  private readonly _ngUnsubscribe: Subject<any> = new Subject();

  public localities: any = [];
  
  @Input() public mode!: string;

  @ViewChild('suburbSearch') public suburbSearch!: MatInput;

  public controlGroup = new FormGroup({
    searchControl: new FormControl(),
    radiusControl: new FormControl(0, [Validators.min(0), Validators.max(2000)])
  });

  public filterObj: any = {};

  // list of already selected items
  public selectedItem!: any;
  private fuseSearch!: Fuse<any>;
  public searchItems!: any;

  public query: string = '';

  public hideTray: boolean = false;
  public showSearch: boolean = true;

  public errorMatcher = new ErrorStateMatcher();

  selected(ev: any): void {

    // log analytics event
    this.$gaService.event('search_location', 'map_click', ev.option.value.label);

    // get result
    let res: any = ev.option.value; 

    // set params
    let qParams = this.filterObj;

    qParams['loc'] = res.id;

    // send params to router
    this.router.navigate(['/'], {
      queryParams: qParams,
      // queryParamsHandling: 'merge',
      preserveFragment: true
    }).catch((err) => console.error(`Navigation error - ${err}`));

  }

  // search list
  public searchList(ev: KeyboardEvent): void {

    // skip for up and down arrows
    if (['ArrowDown', 'ArrowUp'].indexOf(ev.code) > -1) return;
    
    // get element
    let element = ev.target as HTMLInputElement;
    
    // get value
    let val: string = element.value;

    // pass if value is less than 3 chars
    if (val && val.length < 3) return;

    // store query
    // this.query = val;

    // perform search on Fuse object
    this.searchItems = this.fuseSearch.search(val);
  }

  getLabel(option: any): string {
    if (option && option !== null)
      return option.label
    else
      return ''
  }

  // clone object
  public clone(obj: any): any {

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        // copy date value
        let copy: Date = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        let copy: any[] = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = this.clone(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      let copy: Object = {};
      for (var attr in obj) {
            //@ts-ignore
            if (obj.hasOwnProperty(attr)) copy[attr] = this.clone(obj[attr]);
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  public validateNumberValue(ev: KeyboardEvent): void {

    let targ = <HTMLInputElement>ev.target;

    console.warn(this.errorMatcher);
    if (targ.value > targ.max) {
      targ.classList.add('app-invalid');
    }
  }

  public searchRadius(ev: any): void {
    
    // do radius search
    // set params
    let qParams = this.filterObj;

    let rad = this.controlGroup.get('radiusControl')?.value
    
    if (!this.controlGroup.valid) return;

    qParams['radius'] = rad;

    // send params to router
    this.router.navigate(['/'], {
      queryParams: qParams,
      // queryParamsHandling: 'merge',
      preserveFragment: true
    }).catch((err) => console.error(`Navigation error - ${err}`));

  }

  public clearForm(): void {

    if (this.mediaObserver.isActive('lt-md') && this.mode != 'inline') {
      this.hideSearchBox();
    }

    this.selectedItem = null;
    this.controlGroup.get('searchControl')?.setValue(null);
    this.controlGroup.get('radiusControl')?.setValue(null);

    // do radius search
    // set params
    let qParams = this.filterObj;

    delete qParams['radius'];
    delete qParams['loc'];

    // send params to router
    this.router.navigate(['/'], {
      queryParams: qParams,
      // queryParamsHandling: 'merge',
      preserveFragment: true
    }).catch((err) => console.error(`Navigation error - ${err}`));

  }

  public showSearchBox(): void {
    this.showSearch = true;
    this.hideTray = false;
  }

  public hideSearchBox(): void {
    this.showSearch = false;
    this.hideTray = true;
  }

  ngOnInit(): void {

    if (this.mediaObserver.isActive('lt-md') && this.mode != 'inline') {
      this.hideSearchBox();
      
    }

    // disable clicks on control
    DomEvent.disableClickPropagation(this.ref.nativeElement);

    // store empty list of search items
    this.searchItems = [];

    // observe route parameters for filtering
    this.route.queryParamMap
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe(
      {
        next: (params: any) => {

          // load params (if they exist)
          this.filterObj = Object.keys(params["params"]).length === 0 ? {} : this.clone(params["params"]);

          this.localitiesService.getLocalityList()
            .pipe(takeUntil(this._ngUnsubscribe))
            .subscribe({
            next: (list) => {

              this.localities = list;

              this.selectedItem = this.clone(this.localities).filter((item: any) => {
                return item.id == this.filterObj.loc
              })[0]
              
              this.controlGroup.get('searchControl')?.setValue(this.selectedItem);
              this.controlGroup.get('radiusControl')?.setValue(this.filterObj.radius);
      
              this.fuseSearch = new Fuse(this.localities, {
                shouldSort: true,
                minMatchCharLength: 3,
                // this is a low threshold, where 1.0 would match everything
                threshold: 0.1,
                keys: ['label']
              })

            }
          })
          
        }
      }
    )

  }

  // handle unsubscriptions
  ngOnDestroy() {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

}
