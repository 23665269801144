import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap, share } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { SessionService } from './session.service'
import { PsAppConfig } from './../definitions/config'

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  // json url
  private resourceURL: string = '../data/config.json';

  // session key
  private sessionKey: string = 'CONFIG';

  private allConfig!: Observable<any>;

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation: string = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  // load providers directly from JSON
  private _loadConfig(): Observable<any> {

    if (this.allConfig) {
      return this.allConfig
    }

    // get config json
    this.allConfig  = this.http.get<any>(this.resourceURL).pipe(

      share(),

      // log on loaded event, store received to session
      tap((config: any) => {

        // save loaded config data to session
        // this.session.set(this.sessionKey, config);

      }),

      // log error
      catchError(this.handleError('_loadConfig', []))
    )

    return this.allConfig;
  }

  // fetch schools from session
  private _fetchConfig(): Observable<any> {

    // get schools from session using key
    return this.session.get(this.sessionKey).pipe(

      // map and filter by id
      // map(),

      // log error
      catchError(this.handleError('_fetchConfig', []))
    );
  }

  // get Providers using params
  getConfig(): Observable<any> {

    // if key exists in session, load it
    if (this.session.isSet(this.sessionKey)) {
      return this._fetchConfig();
    // otherwise load the json
    } else {
      return this._loadConfig();
    }

  }

  constructor(
    private http: HttpClient,
    private session: SessionService,
  ) { }
}
