<div class="container" fxLayout="column" fxLayoutAlign="start none">
    <div *ngIf="pub || loggedIn" fxLayout="row">
        <!-- navbar -->
        <div fxFlex>
            <app-navbar (log)="logout()"></app-navbar>
            
        </div>
    </div>
    <div fxHide fxShow.lt-md="true" [ngClass.lt-md]="'white-fill'" *ngIf="pub || loggedIn">
        <app-filter-list></app-filter-list>
    </div>
    
    <div *ngIf="!pub">
        <div *ngIf="!loggedIn" fxLayout="column" fxLayoutAlign="space-around center">
            <img alt="Login" class="login-logo" src="./../assets/CbyCLogo_purpleandgreen_square.png" />
            <h1>
                Web Map
            </h1>
            <button aria-label="Log in" mat-stroked-button (click)="login()">Log in</button>
        </div>
        
    </div>
    
    <!-- main body -->
    <div fxLayout="row" fxFlex *ngIf="loggedIn || pub">
        <!-- filter -->
        <div fxFlex="20" fxFlex.gt-md="15" fxShow fxHide.lt-md="true">
            <div fxFlex class="list-wrapper">
                <app-filter *ngIf="!resp"></app-filter>
            </div>
        </div>

        <div fxFlex="nogrow" fxLayoutAlign="space-around center" fxHide fxShow.lt-md="true">
            <!-- <button mat-mini-fab (click)="filterSideNav.toggle()" >
                <span *ngIf="filterSideNav.opened" style="writing-mode: vertical-lr; text-orientation: upright">
                    <mat-icon>map</mat-icon>
                </span>
                <span *ngIf="!filterSideNav.opened" style="writing-mode: vertical-lr; text-orientation: upright">
                    <mat-icon>filter_list</mat-icon>
                </span>
            </button> -->
            <button aria-label="Open filters" mat-mini-fab (click)="openOnboardPopupDialog()">
                <mat-icon>filter_list</mat-icon>
            </button>
        </div>
        
        <mat-sidenav-container fxFlex fxHide fxShow.lt-md="true" *ngIf="resp">

            <mat-sidenav #filterSideNav mode="over" class="resp-tray" fxLayout="column">

                <app-filter *ngIf="resp"></app-filter>

            </mat-sidenav>
            <mat-sidenav-content>
                
                <app-map *ngIf="resp"></app-map>

            </mat-sidenav-content>
        </mat-sidenav-container>

        <!-- list -->
        <div fxFlex="25" fxFlex.gt-md="20" fxShow fxHide.lt-md="true" fxLayout="column">
            <div fxFlex="nogrow">
                <app-filter-list *ngIf="!resp"></app-filter-list>
            </div>
            <div fxFlex class="list-wrapper">
                <app-list *ngIf="!resp"></app-list>
            </div>
        </div>
        <!-- map -->
        <div fxFlex fxShow fxHide.lt-md="true">
            <app-map *ngIf="!resp"></app-map>
        </div>
    </div>
</div>
