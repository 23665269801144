import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';

import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Logger, CryptoUtils } from 'msal';

import { MediaObserver } from '@angular/flex-layout';

import { environment } from './../environments/environment';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { SessionService } from './services/session.service'

import { OnboardPopupComponent } from './popups/onboard-popup/onboard-popup.component'
import { SurveyPopupComponent } from './popups/survey-popup/survey-popup.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  // create array of subscriptions
  private subscriptions: Subscription[] = [];

  title = 'Children By Choice - Web Map';

  // create vars
  isIframe: boolean = false;
  loggedIn: boolean = false;
  loggedInSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loggedInObs = this.loggedInSource.asObservable();

  // get public env variable
  public pub: boolean = environment.public;

  public resp: boolean = false;

  constructor(
    private broadcastService: BroadcastService,
    private authService: MsalService,
    private mediaObserver: MediaObserver,
    public onboardPopupDialog: MatDialog,
    public surveyPopupDialog: MatDialog,
    private sessionService: SessionService,
  ) { 
    this.resp = this.mediaObserver.isActive('lt-md')
  }

  public openOnboardPopupDialog(): void {
    this.onboardPopupDialog.open(OnboardPopupComponent, <MatDialogConfig>{
      maxHeight: '100%',
      // scrollStrategy: 
    });

  }

  private _make4digitIDstring(): string {
    const stringArr = [];
    for(let i = 0; i< 4; i++){
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }

  ngOnInit() {

    console.debug('init app');

    // listen for changes of login
    this.loggedInObs.subscribe({
      next: (val) => {
        // set standard flag when calling next() on observable
        this.loggedIn = val;

        // if login found and on responsive device, open nav popup
        if(this.mediaObserver.isActive('lt-md') && this.loggedIn) this.openOnboardPopupDialog();
      }
    })

    // if (this.mediaObserver.isActive('lt-md') && this.pub) {
    if (this.mediaObserver.isActive('lt-md') && this.pub) {

      this.openOnboardPopupDialog();
    }
    
    // check if public - if not, then login
    if (!this.pub) {

      // create subscriptions
      let loginSuccessSubscription: Subscription;
      let loginFailureSubscription: Subscription;
      
      // check for iFrame
      this.isIframe = window !== window.parent && !window.opener;
      
      // check account
      this.checkAccount();
      
      // ubscribe to login success
      loginSuccessSubscription = this.broadcastService.subscribe('msal:loginSuccess', () => {
        this.checkAccount();
      });
      
      // subscribe to login failure
      loginFailureSubscription = this.broadcastService.subscribe('msal:loginFailure', (error) => {
        console.warn('Login Fails:', error);
      });
      
      // add subs to array
      this.subscriptions.push(loginSuccessSubscription);
      this.subscriptions.push(loginFailureSubscription);
      
      // handle error callback
      this.authService.handleRedirectCallback((authError, response: any) => {
        if (authError) {
          console.error('Redirect Error: ', authError.errorMessage);
          return;
        }
  
        console.debug('Redirect Success: ', response.accessToken);
      });
      
      // set logger
      this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
        console.debug('MSAL Logging: ', message);
      }, {
        correlationId: CryptoUtils.createNewGuid(),
        piiLoggingEnabled: false
      }));
      
    }

  }

  ngOnDestroy(): void {
    // delete subs on destroy
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngAfterViewInit(): void {
    if (this.pub && environment.survey) {
      if (!this.sessionService.isSet('survey')) {
        setTimeout(() => {
          this.surveyPopupDialog.open(SurveyPopupComponent);
          this.sessionService.set('survey', this._make4digitIDstring());
        }, 10000)
      } 
    }
  }

  checkAccount() {
    // get logged in status
    this.loggedInSource.next(!!this.authService.getAccount());
  }

  login() {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

    if (isIE) {
      this.authService.loginRedirect();
    } else {
      this.authService.loginPopup();
    }
  }

  logout() {
    this.authService.logout();
  }


}
