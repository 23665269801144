


  <!-- header -->
<h1 mat-dialog-title [ngClass]="data.type.toLowerCase()">{{ data.name }}</h1>
<div mat-dialog-content>
  <!-- details -->
  <p>{{ data.address_label }}</p>
  <p [outerHTML]="data.phone_label | extractPhoneNum"></p>
  <p *ngIf="data.hours_label">{{ data.hours_label }}</p>
  <!-- accordion -->
  <mat-expansion-panel>
      <mat-expansion-panel-header>
          <mat-panel-title>
              Services
          </mat-panel-title>
      </mat-expansion-panel-header>
      <!-- accordion contents -->
      <!-- ng-template + matExpansionPanelContent means lazy loading - no more rendering before we need to!!! -->
      <ng-template matExpansionPanelContent>
          <!-- show list of properties -->
          <div>
              <ul>
                  <ng-container *ngFor="let v of data | keyvalue" >
                      <li *ngIf="v.value === true && (v.key !== 'public' && v.key !== 'gp_ref' && v.key !== 'bulk_billing' && v.key !== 'last_validated')" [ngClass]="findClass(v.key)">{{ findLabel(v.key) }}</li>
                  </ng-container>
              </ul>
          </div>
          <!-- show notes -->
          <div *ngIf="data.notes">
              <h3>Notes</h3>
              <p class="notes-text" [innerHtml]="data.notes"></p>
          </div>
      </ng-template>
  </mat-expansion-panel>
</div>
<div mat-dialog-actions>
  <button aria-label="Close provider info window" mat-stroked-button (click)="cloeDialog()">Close</button>
</div>