

<!-- Rows-column -->

<div 
    class="header-lower" 
    fxLayout="row" fxLayoutAlign="space-around center">
    <!-- left -->
    <!-- full size -->
    <!--<div class="head-logo" [fxHide.lt-md]="true">
        <a href="https://www.childrenbychoice.org.au/" target="blank">
            <img alt="Children by Choice" flex src="./../../assets/Logo_clip.png"/>
        </a>
    </div>-->
    <div [fxHide.lt-md]="true" fxFlex="nogrow" class="back">
        <a href="https://www.1800myoptions.org.au">
            <img alt="Go Back" flex src="./../../assets/go-back-button.jpg" />
        </a>
    </div>
    <!-- middle -->
    <!--<div 
        fxFlex fxFill fxLayout="column" fxLayoutAlign="center none" 
        class="title-container"
        [fxHide.lt-md]="true">
        <h1 class="title" >Abortion & Contraception Services</h1>
    </div>-->
    <div  [fxHide.lt-md]="true" class="head-logo">

        <img  alt="1800 My Options" flex src="./../../assets/logo.png"/>

    </div>

    <!-- right -->
    <!--<div [fxHide.lt-md]="true" fxFlex="nogrow" class="call-text-container" fxLayout="column" fxLayoutAlign="flex-end none" >

        <a aria-label="Call Children by Choice on 1800 177 725" fxFlex="nogrow" fxLayout="row" class="header-call" href="tel:1800177725">

            <img alt="Call Children by Choice on 1800 177 725" src="../../assets/Icon.svg" class="phone-icon" />

            <div fxFlex class="right-tray">
                <p class="header-text">Queensland Wide Counselling, Information and Referral Services</p>
                <h3>1800 177 725</h3>
            </div>
            
        </a>
    </div>-->
    <div [fxHide.lt-md]="true" fxflex="nogrow" class="back" hide-sm hide-xs ng-if="showButton" fxLayout="column">
		<a href="https://www.google.com.au/"><img flex src="https://myoptions.naturallogic.info/sites/default/files/quick-escape.jpg" /></a>
	</div>         


    <!-- responsive - existing -->
    <!-- <div [fxHide.gt-sm]="true" fxFlex="nogrow" class="call-text-container" fxLayout="row">
        <div class="head-logo">
            <a href="https://www.childrenbychoice.org.au/" target="blank">
            <img alt="Children by Choice" flex src="./../../assets/Logo_clip.png" style="width: 193px; "/>
            </a>
        </div>

        <a aria-label="Call Children by Choice on 1800 177 725" fxFlex="nogrow" fxLayout="row" fxLayoutAlign="center center" class="header-call" href="tel:1800177725">

            <img alt="Call 1800 177 725" src="../../assets/Icon.svg" style="width: 40px" />

            <div fxFlex style="padding: 10px">
                <h3>1800 177 725</h3>
            </div>
            
        </a>

        <button aria-label="Log out" mat-stroked-button *ngIf="loggedIn" (click)="logout()">Log out</button>
    </div> -->

    <div [fxHide.gt-sm]="true" fxFlex fxLayout="column">
        
        
        
        <div fxLayout="row" fxLayoutAlign="space-around center">

            <a href="https://www.1800myoptions.org.au" target="blank" class="resp-head-logo">
                <img alt="1800 My Options" flex src="./../../assets/logo.png"/>
            </a>
            
            <!--<a href="https://www.1800myoptions.org.au" target="blank" fxFlex="50%">
                <img alt="Children by Choice" flex src="./../../assets/CByCLogo_purpleandgreen_sm.png" class="resp-logo"/>
            </a>-->

            <!-- responsive -->
            <!--<div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="!loggedIn">
                <a aria-label="Call Children by Choice on 1800 177 725" fxFlex="nogrow" class="header-call" href="tel:1800177725">

                    <img alt="Call 1800 177 725" src="../../assets/Icon.svg" class="phone-icon" />
    
                </a>

                <div fxFlex>
                    <h3 class="resp-header-phone">1800 177 725</h3>
                </div>
            </div>

            <button aria-label="Log out" mat-stroked-button *ngIf="loggedIn" (click)="logout()">Log out</button>-->
        </div>

        <!--<div fxFlex>
            <h1 class="title-resp">Abortion & Contraception Services</h1>
        </div>-->
        
    </div>

</div>

