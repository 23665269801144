import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigService } from '../../services/config.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PsAppConfig, PsFilterConfig } from '../../definitions/config';

@Component({
  selector: 'app-provider-dialog',
  templateUrl: './provider-dialog.component.html',
  styleUrls: ['./provider-dialog.component.scss']
})
export class ProviderDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ProviderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private configService: ConfigService,
  ) { }

  // manage unsubscriptions
  private readonly _ngUnsubscribe: Subject<any> = new Subject();

  public filterConfig!: PsFilterConfig;

  cloeDialog(): void {
    this.dialogRef.close();
  }

  // get labels for filter params from config
  public findLabel(key: string | any): string {
    
    // split main name in group and property
    var group = key.split('_')[0];
    
    if (this.filterConfig[group]) {
      
      return this.filterConfig[group].entries[key].list_label;
    
    // otherwise select by key
    } else if (this.filterConfig[key]) {
      
      return this.filterConfig[key].entries[key].list_label;
      
    } else {
      
      return ''

    }
    
  }
  
  // get class for bullet points
  public findClass(key: string | any) {

    if (typeof key != 'string')
      return ''
    
    var group: string = key.split('_')[0];
    
    // if there is a class for the item get it
    if (this.filterConfig[group].entries[key]['class'] && this.filterConfig[group].entries[key]){ 
    
      return this.filterConfig[group].entries[key]['class'];
    
    } else {
      
      return '';
      
    }
    
  }

  ngOnInit(): void {

    this.configService.getConfig()
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe((items: PsAppConfig) => {
        
        this.filterConfig = items.filterElements;

    });
    
  }

}
