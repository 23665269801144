<div fxLayout="row" class="filter-list-header" fxLayoutAlign="space-around center">
    <h1 fxShow fxHide.lt-md="true">Results</h1>
    <button aria-label="Open list results" mat-stroked-button fxHide fxShow.lt-md (click)="openListDialog()"><mat-icon>list</mat-icon> {{ providerCount }} results</button>
    <p fxShow fxHide.lt-md>{{ providerCount }} results</p>
    <button aria-label="Clear filters" mat-icon-button (click)="clearFilters()" *ngIf="chipList.length > 0"><mat-icon>close</mat-icon></button>
</div>
<div class="filter-list-container">
    <mat-chip-list fxShow fxHide.lt-md="true">
        <mat-chip 
            [removable]="true"
            (removed)="removeChip(item)"
            *ngFor="let item of chipList">
            {{ item.label }}
            <mat-icon matChipRemove>close</mat-icon>
        </mat-chip>
    </mat-chip-list>
    <div class="hor-scroll" fxHide fxShow.lt-md="true"> 
        <mat-chip-list class="mat-chip-list no-wrap">
            <mat-chip 
                [removable]="true"
                (removed)="removeChip(item)"
                *ngFor="let item of chipList">
                {{ item.label }}
                <mat-icon matChipRemove>close</mat-icon>
            </mat-chip>
        </mat-chip-list>
    </div>
    
    <hr fxShow fxHide.lt-md="true">
    <div *ngIf="providerCount < 1" class="no-data">
        No service found for the current filters
    </div>
</div>




