import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objSort'
})
export class ObjSortPipe implements PipeTransform {
  /**
   * Sort and object based on a particular property
   * @param obj obj to be sorted
   * @param field property / field to be sorted on
   * @returns 
   */
  transform(obj: any, field: string): any {
    // catch empty obj
    if (!obj) {
      return obj
    }
    // sorry array of objects by value.sort
    obj.sort((a: any, b: any) => {
      // if it has a "value" field, use that as a sub-group
      if (a.hasOwnProperty('value')) {
        return a.value[field] - b.value[field]
      // otherwise just sort by field directly on the object
      } else {
        if(a[field] < b[field]) { return -1; }
        if(a[field] > b[field]) { return 1; }
        return 0;
      }
    })

    return obj;
  }

}
