import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractPhoneNum'
})
export class ExtractPhoneNumPipe implements PipeTransform {

  /**
   * Format a string of phone numbers into a list of tel links separated by ' or '
   * @param value string of phone numbers broken by any number of chars, e.g. 1800 999 999 or 1800 888 888
   * @param args none
   * @returns Formatted <a></a> element with a tel: style, e.g. <a href="tel:1800 999 999">1800 999 999</a> or <a href="tel:1800 888 888">1800 888 888</a>
   */
  transform(value: string, ...args: unknown[]): string {
    // strip any  groups of chars and replace with |, then filter any empty strings
    let l = value.replace(/[a-zA-Z]/g, '|').split('|').filter((v) => { return v !== '' });
    // format phone numbers into <a></a> elements
    let fl = l.map((val) => {
      return `<a href="tel:${val.trim()}">${val.trim()}</a>`
    })
    // return the string, joined with 'or'
    return fl.join(' or ');
  }

}
