import { Injectable } from '@angular/core';

import { SessionStorageService } from 'ngx-store';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  // get value from storage for key
  get(key: string): Observable<string> {
    // create observable of value from storage load
    return of(this.sessionStorage.load(key).value);
  }

  set(key: string, val: any): void {
    // store key + value pair in session storage
    return this.sessionStorage.set(key, val);
  }

  isSet(key: string): boolean {
    // check if key exists with data in session
    return this.sessionStorage.load(key).value != null;
  }

  constructor(private sessionStorage: SessionStorageService) { }
}
