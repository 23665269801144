import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ConfigService } from '../../../services/config.service';

import { DomEvent } from 'leaflet'
import { PsAppConfig, PsFilterEntriesItem } from 'src/app/definitions/config';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit, OnDestroy {

  constructor(
    private configService: ConfigService,
    private ref: ElementRef
  ) { }

  // manage unsubscriptions
  private readonly _ngUnsubscribe: Subject<any> = new Subject();

  // make empty obj
  public legend: any[] = [];

  // initiate + subscribe to getSchools on service
  private _getLegendconfig(): void {

    this.configService.getConfig()
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe((items: PsAppConfig) => {
        
        
        for (const [key, value] of Object.entries(items.filterElements.type.entries)) {
          (value as any)["code"] = key.toLowerCase();

          this.legend.push(value)
        }

      });
    
  } 

  ngOnInit(): void {
    this._getLegendconfig();
    
    // disable clicks on control
    DomEvent.disableClickPropagation(this.ref.nativeElement);
  }

  // handle unsubscriptions
  ngOnDestroy() {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

}
