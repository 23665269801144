import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router, ParamMap, Params } from '@angular/router';

import { ProvidersService } from '../services/providers.service';
import { ConfigService } from '../services/config.service';
import { SelectionService } from '../services/selection.service';
import { PsAppConfig, PsFilterConfig } from '../definitions/config';

import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {

  constructor(
    private providersService: ProvidersService,
    private route: ActivatedRoute,
    private router: Router,
    private configService: ConfigService,
    private selectionService: SelectionService,
    private $gaService: GoogleAnalyticsService,
  ) { }

  // manage unsubscriptions
  private _ngUnsubscribe: Subject<any> = new Subject();

  public providers!: any[];
  public providerCount!: number;
  public filterObj!: Params;
  public filterConfig!: PsFilterConfig;
  public renderList: any[] = [];

  public checkSelection: any;
  
  // initiate + subscribe to getSchools on service
  private _getListConfig(): void {

    this.configService.getConfig()
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe((items: PsAppConfig) => {
        
        this.filterConfig = items.filterElements;

      });
    
  } 

  // initiate + subscribe to getSchools on service
  private _getListProviders(params: Params): void {

    this.providersService.getProviders(params)
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe((providers: any[]) => {

        // save schools
        this.providers = providers.map((prov) => {

          return prov.properties

        }); 

        this.providerCount = providers.length;
        
      });
    
  } 

  // get labels for filter params from config
  public findLabel(key: string | any): string {
    
    // split main name in group and property
    var group = key.split('_')[0];
    
    if (this.filterConfig[group]) {
      
      return this.filterConfig[group].entries[key].list_label || '';
    
    // otherwise select by key
    } else if (this.filterConfig[key]) {
      
      return this.filterConfig[key].entries[key].list_label || '';
      
    } else {
      
      return ''

    }
    
  }
  
  // get class for bullet points
  public findClass(key: string | any) {

    if (typeof key != 'string')
      return ''
    
    var group: string = key.split('_')[0];
    
    // if there is a class for the item get it
    if (this.filterConfig[group].entries[key]['class'] && this.filterConfig[group].entries[key]){ 
    
      return this.filterConfig[group].entries[key]['class'];
    
    } else {
      
      return '';
      
    }
    
  }

  private _scrollToItem(id: number): void {

    // clear existing highlights
    this._clearHighlight();

    // get target element
    let el: HTMLElement | null = document.getElementById(`${id}`);

    // if the element can be found (which it should)
    if (el !== null) {
      // scroll it
      if (this.checkSelection != id) el.scrollIntoView();
      // add highlight style
      el.classList.add('highlight-result');
    }

  }

  // clear highlight
  private _clearHighlight(): void {
    // get all els that currently have highlight set
    let elems = document.querySelectorAll(".highlight-result");
    
    // loop and remove
    [].forEach.call(elems, (el: HTMLElement) => {
      el.classList.remove("highlight-result");
    });

  }

  // compare two objects by serialisaing them and testing if they are equal
  // this works because the stored parameters are copied directly from the read parameters
  // so if there is no change, this should be reliable. If not, it will just return true anyway
  public serialsUnequal(a: any | undefined, b: any | undefined): boolean {
    // if a or b are undefined, return true
    if (a == undefined || b == undefined) return true;
    return JSON.stringify(a) != JSON.stringify(b)
  }

  public emitHightlight(id: number, name: string): void {
    this.checkSelection = id;
    this.selectionService.setId(id);

    // log analytics event
    this.$gaService.event('select_provider_list', 'map_click', name);
  }

  ngOnInit(): void {

    console.debug('list init');

    // observe route parameters for filtering
    this.route.queryParamMap
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe(
      {
        next: (params: Params) => {

          this._getListConfig();

          // check if route params and stored params are "unequal"
          // return true to trigger a reload, or false to not reload
          let reload: boolean = this.serialsUnequal(this.filterObj, params["params"]);

          // load params (if they exist)
          this.filterObj = Object.keys(params["params"]).length === 0 ? null : params["params"];

          // get schools with params
          if (reload) this._getListProviders(this.filterObj);
          
        }
      }
    )

    this.selectionService.getId()
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe({
      next: (val) => {
        // if id is set, scroll to it
        if (val !== null) this._scrollToItem(val);
        // if it's null, clear the highlight
        else this._clearHighlight()
      }
    })

  }

  // handle unsubscriptions
  ngOnDestroy() {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

}
