import { Component, OnInit, EventEmitter, Output, OnDestroy, ElementRef } from '@angular/core';

import { Subject } from 'rxjs';

import { DomEvent } from 'leaflet'

@Component({
  selector: 'app-fitzoom',
  templateUrl: './fitzoom.component.html',
  styleUrls: ['./fitzoom.component.scss']
})
export class FitzoomComponent implements OnInit, OnDestroy {

  // create output for panning map
  @Output('fit') fitResult: EventEmitter<any> = new EventEmitter<any>();

  // manage unsubscriptions
  private readonly _ngUnsubscribe: Subject<any> = new Subject();

  fitBounds(): void {

    this.fitResult.emit();

  }

  constructor(
    private ref: ElementRef,
  ) { }

  ngOnInit(): void {

    // disable clicks on control
    DomEvent.disableClickPropagation(this.ref.nativeElement);

  }

  // handle unsubscriptions
  ngOnDestroy() {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

}
