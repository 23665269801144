import { Injectable } from '@angular/core';
import { Subject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectionService {
  

  // make observable
  private select: Subject<number | null>;

  constructor() {
    // set value
    this.select = new Subject<number | null>();
  }

  // make getter
  getId(): Observable<number | null> {
    return this.select.asObservable();
  }

  // make setter
  setId(newValue: number | null): void {
    this.select.next(newValue);
  }
}
