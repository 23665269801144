import { Injectable } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, map, tap, share } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { SessionService } from './session.service'
import { LocalitiesService } from './localities.service'

import pointsWithinPolygon from '@turf/points-within-polygon';
import { Feature, FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { Point } from 'leaflet';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProvidersService {

  // json url
  private resourceURL: string = '../data/services.json';

  // session key
  private sessionKey: string = 'PROVIDERS';

  private providers!: Observable<any>;
  private locality!: any;

  private provCountSrc: Subject<any> = new Subject<any>();
  public provCountObs: Observable<Subject<any>> = this.provCountSrc.asObservable();

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation: string = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private _gestationLogic(provider: GeoJsonProperties, minmax: string): boolean {
    
    let vals = JSON.parse(`[${minmax}]`)
    let f_min = vals[0];
    let f_max = vals[1];

    // @ts-ignore
    let p_min = provider.gestation_min || 0;
    // @ts-ignore
    let p_max = provider.gestation_max;

    // if no max throw error
    if (p_max === undefined) {
      throw 'Error - Need maximum gest value';
    }

    // no overlap low
    if (p_min < f_min && p_max < f_min) { return false }

    // overlap low
    if (p_min < f_min && p_max > f_min && p_max < f_max) return true

    // is contained
    if (p_min >= f_min && p_max <= f_max) return true

    // contains
    if (p_min < f_min && p_max > f_max) return true 

    // overlap high
    if (p_min >= f_min && p_min < p_max && p_max > f_max) return true 

    // no overlap high
    if (p_min > f_max && p_max > f_max) return false
    
    return true
  }

  private _filterProviders(provider: Feature<any>, params: any): boolean {

    // skip if no params return all
    if (!params) return true;
    else {

      // result object
      let res: boolean[] = [];

      // @ts-ignore
      for (const [key, prop] of Object.entries(provider.properties)) {

        // if it's an easy data property, then filter
        if (params.hasOwnProperty(key)) {
          // if boolean, then just use bool value
          if (typeof prop == 'boolean') res.push(prop);
          // otherwise, see if the value is in the param list - return bool from that
          else if (params[key].length > 0) res.push( params[key].indexOf(prop) > -1 );
        }

      }

      // check if there are any falses now to see if we need to run the gestation filter
      // @ts-ignore
      if ((provider.properties.hasOwnProperty('gestation_min') || provider.properties.hasOwnProperty('gestation_max'))
          && params.hasOwnProperty('gestation')) {
        res.push(this._gestationLogic(provider.properties, params['gestation']));
      }

      // check if there are any falses now to see if we need to run the spatial filters
      if (res.indexOf(false) > 0) return false;

      if (params.hasOwnProperty('loc')) {

        let buff: number = 0;

        if (params.hasOwnProperty('radius')) {
          buff = params.radius;
        }
          
        if (this.locality) {

          let pol: FeatureCollection = pointsWithinPolygon(provider, this.locality);
          res.push(pol.features.length > 0);

        }
        else {
          
          this.localitiesService.getBufferedLocalityById(params.loc, buff).subscribe({
            next: (item: Feature) => {
              this.locality = item;
              let pol = pointsWithinPolygon(provider, this.locality);
              res.push(pol.features.length > 0);
            }
          });
        }
        
      }

      // only return if there are no falses
      return res.indexOf(false) < 0;
    }
    
  }

  // fetch schools from session
  private _fetchProviders(params: Params): Observable<any> {

    // get schools from session using key
    return this.session.get(this.sessionKey).pipe(

      // map and filter by id
      map((providers: any) => 
        
        // filter schools as per params
        providers.features.filter((provider: any) => this._filterProviders(provider, params)
        )),
      
      tap((providers: any) => {
        this.provCountSrc.next(providers.length);
      }),

      // log error
      catchError(this.handleError('_fetchSchools', []))
    );
  }

  // load providers directly from JSON
  private _loadProviders(params: any): Observable<any> {

    if (this.providers) {
      return this.providers.pipe(
        // map and filter by id
        map((providers: any[]) =>
          
          // filter providers as per params
          // @ts-ignore
          providers.filter((provider: any) => this._filterProviders(provider, params))
          
        ),
        tap((providers: any) => {
          this.provCountSrc.next(providers.length);
        }),
      )
    }

    // get providers json
    this.providers =  this.http.get<any[]>(this.resourceURL).pipe(
      share(),
      // log on loaded event, store received to session
      tap((providers: any[]) => {

        // save loaded providers data to session
        this.session.set(this.sessionKey, providers);

      }),

      // map and filter by id
      map((providers: any[]) =>
      
        // filter providers as per params
        // @ts-ignore
        providers.features.filter((provider: any) => this._filterProviders(provider, params))
        
      ),

      tap((providers: any) => {
        this.provCountSrc.next(providers.length);
      }),

      // log error
      catchError(this.handleError('_loadProviders', []))
    )

    return this.providers
  }

  // get Providers using params
  getProviders(params: any): Observable<any> {

    // empty locality
    this.locality = undefined;

    // if key exists in session, load it
    if (this.session.isSet(this.sessionKey)) {
      return this._fetchProviders(params);
    // otherwise load the json
    } else {
      return this._loadProviders(params);
    }

  }

  public getProviderCount(): Observable<any> {
    return this.provCountObs
  }

  constructor(
    private http: HttpClient,
    private session: SessionService,
    private localitiesService: LocalitiesService,
  ) { }
}
