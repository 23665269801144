<h1 mat-dialog-title>
    Survey
</h1>
<mat-dialog-content class="mat-typography" >
    <p>Hello, this is some text.</p>
    <p>
        Please consider completing our <a href="https://www.google.com.au" target="blank">Survey</a>
    </p>
</mat-dialog-content>
<div mat-dialog-actions>
    <button aria-label="Close survey window" mat-stroked-button (click)="closeDialog()">Close</button>
</div>
