import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-onboard-popup',
  templateUrl: './onboard-popup.component.html',
  styleUrls: ['./onboard-popup.component.scss']
})
export class OnboardPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<OnboardPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  public selectedIndex: number = 0;

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {


  }

}
