import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccordionStateService {

  public state: any = {}

  public set(key: string, val: boolean): void {
    this.state[key] = val;
  }

  public get(key: string): boolean {

    if (this.state.hasOwnProperty(key)) return this.state[key]
    else return false;

  }

  constructor() { }
}
