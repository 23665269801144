<div ngClass.gt-sm="list" >
    <div *ngFor="let item of providers | objSort:'name' ; let odd = odd " class="list-item" [ngClass]=" odd ? 'odd' : 'even' " id="{{item.id}}">
        <!-- header -->
        <h2 [ngClass]="item.type.toLowerCase()" (click)="emitHightlight(item.id, item.name)">{{ item.name }}</h2>
        <span class="warn" *ngIf="!item.othercrit_nogpreq">* GP referral required</span>
        <div>
            <!-- details -->
            <p>{{ item.address_label }}</p>
            <p [innerHTML]="item.phone_label | extractPhoneNum"></p>
            <p *ngIf="item.hours_label">{{ item.hours_label }}</p>
            <!-- accordion -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Services
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <!-- accordion contents -->
                <!-- ng-template + matExpansionPanelContent means lazy loading - no more rendering before we need to!!! -->
                <ng-template matExpansionPanelContent>
                    <!-- show list of properties -->
                    <div>
                        <ul>
                            <ng-container *ngFor="let v of item | keyvalue" >
                                <li *ngIf="v.value === true && (v.key !== 'public' && v.key !== 'gp_ref' && v.key !== 'bulk_billing' && v.key !== 'last_validated')" [ngClass]="findClass(v.key)">{{ findLabel(v.key) }}</li>
                            </ng-container>
                        </ul>
                    </div>
                    <!-- show notes -->
                    <div *ngIf="item.notes">
                        <h3>Notes</h3>
                        <p class="notes-text" [innerHtml]="item.notes | formatLinkPipe"></p>
                    </div>
                </ng-template>
            </mat-expansion-panel>
        </div>
    </div>
</div>
