import {
    Icon, IconOptions
} from 'leaflet';

// basic map icon with filename from className property
export class mapIcon extends Icon {
    constructor(className: string) {
        super(<IconOptions>{
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            iconUrl: `./assets/markers/marker-icon-${className}.svg`,
            shadowUrl: 'leaflet/marker-shadow.png',
            shadowAnchor: [13, 41]
        });
    };
    
};

// basic map icon highlight, with filename from className property
export class mapHighlightIcon extends Icon {
    constructor(className: string, zIndex: number = 0) {
        super(<IconOptions>{
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            iconUrl: `./assets/markers/marker-icon-${className}-highlight.svg`,
            shadowUrl: 'leaflet/marker-shadow.png',
            shadowAnchor: [13, 41],
            className: className
        });
    };
    
};
