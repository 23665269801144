import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ConfigService } from '../services/config.service';

import { AccordionStateService } from '../services/accordion-state.service';

import { Options as SliderOptions, ChangeContext } from '@angular-slider/ngx-slider';
import { PsAppConfig, PsFilterConfig } from '../definitions/config';
import { MatCheckboxChange } from '@angular/material/checkbox';

import { MediaObserver } from '@angular/flex-layout';

import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private configService: ConfigService,
    private accordionState: AccordionStateService,
    public mediaObserver: MediaObserver,
    private $gaService: GoogleAnalyticsService,
  ) { }

  // manage unsubscriptions
  private _ngUnsubscribe: Subject<any> = new Subject();

  public filterConfig!: PsFilterConfig;
  public filterObj: Params = {};

  // list of selected items
  public selectedItems: any[] = [];

  // slider values
  public sliderLo!: number;
  public sliderHi!: number;

  // slider defaults
  public sliderOptions: SliderOptions = {
    floor: 0,
    ceil: 0
  };

  /**
   * handler for event fired on slider state change.
   * update filters in route accordingly
   * @param ev angular event object from change
   * @param key key for the filter
   */
  public sliderChange(ev: ChangeContext, key: any): void {
    
    let lo: number = ev.value;
    let hi: number | undefined = ev.highValue;

    // hold params
    let qParams: Params = {};

    // check to see if values are max / min, and if they are clear the filter
    if (this.sliderOptions.floor != lo || this.sliderOptions.ceil != hi) this.filterObj[key] = `${lo},${hi}`;
    else if (this.sliderOptions.floor == lo && this.sliderOptions.ceil == hi) delete this.filterObj[key]

    // set params
    qParams = this.filterObj;

    // send params to router
    this.router.navigate(['/'], {
      queryParams: qParams,
      // queryParamsHandling: 'merge',
      preserveFragment: true
    }).catch((err) => console.error(`Navigation error - ${err}`));

  }

  // initiate + subscribe to filter config
  private _getFilterConfig(): void {

    this.configService.getConfig()
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe((items: PsAppConfig) => {

        if (!this.filterObj.hasOwnProperty('gestation')) {
          this.sliderLo = items.filterElements.gestation.entries.min.label as number;
          this.sliderHi = items.filterElements.gestation.entries.max.label as number;
        }

        this.sliderOptions.ceil = items.filterElements['gestation'].entries.max.label as number;

        this.filterConfig = items.filterElements;

      });
    
  } 

  public onFormChange(ev: MatCheckboxChange, value: any, multitype: any = null): void {

    // hold params
    let qParams: Params = {};

    // if not a multitype
    if (multitype === null) {

      // log analytics event
      this.$gaService.event('select_filter', 'map_click', value);

      // if box has been checked, set filtyer to true
      if (ev.checked === true) this.filterObj[value] = true;
      // if unchecked, set to false
      if (ev.checked === false) delete this.filterObj[value];

    // if it is a multitype
    } else {

      // log analytics event
      this.$gaService.event('select_filter', 'map_click', value);

      // if checked
      if (ev.checked === true) {

        // if there is an existing filter, add the current value
        if (this.filterObj.hasOwnProperty(multitype)) {
          // if there's only one filter, it will be a string 
          if (typeof this.filterObj[multitype] == 'string') {
            // convert single string to array
            this.filterObj[multitype] = [this.filterObj[multitype]]
            // add nerw value to array
            this.filterObj[multitype].push(value)
            // otherwise it was already an array
          } else this.filterObj[multitype].push(value)
        }
        // otherwise make a new array with the value in it
        else this.filterObj[multitype] = [value]
      
      // if unchecked
      } else {

        // make sure filter group is set
        if (this.filterObj.hasOwnProperty(multitype)) {

          // if there's a single value, it will be a string
          if (typeof this.filterObj[multitype] == 'string') delete this.filterObj[multitype];
          
          // otherwise it will be a list
          // so filter the item that has been unchecked from the list
          else this.filterObj[multitype] = this.filterObj[multitype].filter((el: any) => {
            return el != value
          });

        }

      }

    }

    // set params
    qParams = this.filterObj;

    // send params to router
    this.router.navigate(['/'], {
      queryParams: qParams,
      // queryParamsHandling: 'merge',
      preserveFragment: true
    }).catch((err) => console.error(`Navigation error - ${err}`));
    
  }

  // clone object
  public clone(obj: any): any {

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        // copy date value
        let copy: Date = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        let copy: any[] = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = this.clone(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      let copy: Object = {};
      for (var attr in obj) {
            //@ts-ignore
            if (obj.hasOwnProperty(attr)) copy[attr] = this.clone(obj[attr]);
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  // compare two objects by serialisaing them and testing if they are equal
  // this works because the stored parameters are copied directly from the read parameters
  // so if there is no change, this should be reliable. If not, it will just return true anyway
  public serialsUnequal(a: any | undefined, b: any | undefined): boolean {
    // if a or b are undefined, return true
    if (a == undefined || b == undefined) return true;
    return JSON.stringify(a) != JSON.stringify(b)
  }

  public getFilterStatus(key: any, group: string | null = null): boolean {
    if (group === null) return this.filterObj.hasOwnProperty(key)
    else return this.filterObj.hasOwnProperty(group) && this.filterObj[group].indexOf(key) > -1
  }

  public getGroupStatus(values: string): boolean {
    for (let x of Object.keys(values)) {
      if (this.filterObj.hasOwnProperty(x)) return true
    }
    return false
  }

  public getAccordionState(key: any, values: any = null): boolean {
    // if (this.getGroupStatus(values) === false) return this.accordionState.get(key);
    // else return true;

    // only use state - do not check filter status
    return this.accordionState.get(key);
    
  }

  public setAccordionState(key: string, toggle: boolean = true): void {
    this.accordionState.set(key, toggle);
  }

  public setSliderValues(filters: Params): void {
    if (filters.hasOwnProperty('gestation')) {

      let vals = JSON.parse(`[${filters.gestation}]`)

      this.sliderLo = vals[0];
      this.sliderHi = vals[1];
    }
  }

  // scroll to element by DOM id
  public scrollAccordion(id: string): void {
    document.getElementById(id)?.scrollIntoView({behavior: 'smooth', block: 'end'});
  }

  ngOnInit(): void {

    // observe route parameters for filtering
    this.route.queryParamMap
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe(
      {
          next: (params: Params) => {

          this._getFilterConfig();

          // load params (if they exist)
          this.filterObj = Object.keys(params["params"]).length === 0 ? {} : this.clone(params["params"]);

          // set incoming values on slider
          this.setSliderValues(this.filterObj);
          
        }
      }
    )

  }

  // handle unsubscriptions
  ngOnDestroy() {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

}