<div class="top right control-top-right">
    <div [ngClass]="{'padding-override': hideTray, 'map-control leaflet-bar leaflet-control search-control': mode != 'inline'}">
        <button aria-label="Show suuburb search box" mat-icon-button *ngIf="hideTray" (click)="showSearchBox()">
            <mat-icon>search</mat-icon>
        </button>
        <form [formGroup]="controlGroup" class="example-form" fxLayout="column" *ngIf="showSearch">
            <div>
                <mat-form-field class="example-full-width">
                    <mat-label>Suburb</mat-label>
                    <input 
                        #suburbSearch
                        type="text"
                        placeholder="Search Suburb or Postcode"
                        aria-label="Suburb"
                        matInput
                        formControlName="searchControl"
                        cdkFocusInitial
                        (keyup)="searchList($event)"
                        [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" [displayWith]="getLabel" autoActiveFirstOption>
                    <mat-option *ngFor="let res of searchItems" [value]="res.item">
                        {{res.item.label}}
                    </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <button type="button" mat-button matSuffix mat-icon-button aria-label="Clear suburb search" (click)="clearForm()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <div *ngIf="selectedItem">
            <!-- <div> -->
                <div *ngIf="mode == 'inline'">
                    <h2>How far can you travel?</h2>
                    <caption class="radius-caption">(leave blank if not relevant)</caption>
                </div>
                <mat-form-field>
                    <mat-error>
                        Radius must be less than 2000 km
                    </mat-error>
                    <mat-label>Search radius (km)</mat-label>
                    <input formControlName="radiusControl" matInput type="number" #radius [min]="0.00" [max]="2000.00" [errorStateMatcher]="errorMatcher" (keyup)="validateNumberValue($event)" (keyup.enter)="searchRadius($event)">
                    
                </mat-form-field>
                <button aria-label="Apply search radius" type="button" mat-button mat-icon-button matSuffix aria-label="Search" [disabled]="!controlGroup.valid" (click)="searchRadius($event)"><mat-icon>search</mat-icon></button>
            </div>
        </form>
    </div>
</div>