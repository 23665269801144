import { Component, OnInit, OnDestroy, Output, EventEmitter, NgZone, ViewChild, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router, ParamMap, Params } from '@angular/router';
import {
  LatLng, latLng, Map, marker, Layer, tileLayer, 
  geoJSON, canvas, LeafletEvent, MapOptions,
  GeoJSON, Point, divIcon, 
  TooltipOptions, Point as LPoint, Marker, PathOptions, MarkerClusterGroup, MarkerClusterGroupOptions
} from 'leaflet';
import { FeatureCollection, Feature, GeometryObject, GeoJsonProperties } from 'geojson';

import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';

import { MediaObserver } from '@angular/flex-layout';

import { ProvidersService } from '../services/providers.service';
import { LocalitiesService } from '../services/localities.service';
import { SelectionService } from '../services/selection.service';
import { ConfigService } from '../services/config.service';

import { MatDialog } from '@angular/material/dialog';

import { ProviderDialogComponent } from './provider-dialog/provider-dialog.component'

// definitions
import { mapIcon, mapHighlightIcon } from '../definitions/mapMarkers';

import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {

  constructor(
    private providersService: ProvidersService,
    private localitiesService: LocalitiesService,
    private selectionService: SelectionService,
    private configService: ConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private zone: NgZone,
    public providerDialog: MatDialog,
    private mediaObserver: MediaObserver,
    private _snackBar: MatSnackBar,
    private $gaService: GoogleAnalyticsService,
  ) { }

  @ViewChild('map') vcRef!: ViewContainerRef;

  // manage unsubscriptions
  private readonly _ngUnsubscribe: Subject<any> = new Subject();

  private _defCenter: LatLng = new LatLng(-37.0265038, 145.1393824);
  
  private _defZoom: number = 7;
  
  
  private _radiusLgAlertSnackbarConfig: MatSnackBarConfig = {
    verticalPosition: 'top',
    horizontalPosition: 'end',
    panelClass: 'radius-snackbar-lg'
  };

  private _radiusRespAlertSnackbarConfig: MatSnackBarConfig = {
    verticalPosition: 'top',
    horizontalPosition: 'center',
    panelClass: 'radius-snackbar'
  };

  @Output() selectedProvider = new EventEmitter<string>();

  public options: MapOptions = {
    layers: [
      tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png', { maxZoom: 18, attribution: 'Carto &copy;' })
    ],
    zoom: this._defZoom,
    center: this._defCenter, 
    renderer: canvas(),
    preferCanvas: true,
    zoomSnap: 0.5,
    zoomDelta: 0.5,
    wheelPxPerZoomLevel: 70
  };

  // default values - overwritten by config
  public clusterOptions: MarkerClusterGroupOptions = {
    // disableClusteringAtZoom: 17,
    // spiderfyOnMaxZoom: true,
    // removeOutsideVisibleBounds: true,
    maxClusterRadius: 0,
    iconCreateFunction: this._styleCluster,
    polygonOptions: <PathOptions> {
      color: '#999',
      dashArray: '7',
      weight: 2
    }
  }

    // initiate + subscribe to getSchools on service
    private _getMapConfig(): void {

      this.configService.getConfig()
        .pipe(takeUntil(this._ngUnsubscribe))
        .subscribe((items: any) => {
          
          this.zone.run(() => {
            this.clusterOptions.removeOutsideVisibleBounds = items.map.clusterOptions.removeOutsideVisibleBounds;
            this.clusterOptions.maxClusterRadius = items.map.clusterOptions.maxClusterRadius;

          });
  
        });
      
    }

  // style cluster function
  private _styleCluster(cl:any): any {
    return divIcon({
      html: '<div class="cluster-text">' + cl.getChildCount() + '</div>',
      iconUrl: 'images/marker-icon-co.png',
      shadowUrl: 'images/marker-shadow.png',
      className: 'cluster-container',
      iconAnchor: [21, 52]
    });;
  }

  // loading flag
  public mapLoading: boolean = true;
  public providersLoading: boolean = true;

  public providers!: Feature[];
  public clusters!: MarkerClusterGroup;
  public providerCount: number = 0;
  public filterObj!: Params;

  // ! bang works around null initialiser
  public map!: Map;

  public localityLayer: GeoJSON = geoJSON(undefined, {
    // @ts-ignore
    style: this._styleLoc
  });

  // make empty school layer and bind styling + tooltip
  public providerLayer: any = geoJSON(undefined,{
    pointToLayer: this._styleProviders,
    onEachFeature: (feature: Feature, layer: Layer) => {
      //@ts-ignore
      layer.bindTooltip( '<span class="' + layer.feature.properties.type.toLowerCase() + '-bare">' + layer.feature.properties.name + '</span>', <TooltipOptions>{
         //@ts-ignore
        className: feature.properties.type,
        direction: 'top',
        offset: new LPoint(1,-40)
      })
        // add click listener
        .on({
          click: (e: LeafletEvent) => {
            // run click event in angular zone
            // must be run in zone or else data is not read until
            // the init on the popup component is run, which is (weirdly)
            // only after a pointermove event
            this.zone.run(() => {

              // log analytics event
              this.$gaService.event('select_provider_map', 'map_click', e.target.feature.properties.name);

              if (this.mediaObserver.isActive('lt-md')) this.openProviderDialog(e.target.feature.properties);
              
              // get id
              var id = e.target.feature.properties.id;

              // clear existing highlight
              this._clearHighlight();

              // set icon to new highlight icon
              e.target.setIcon(new mapHighlightIcon(e.target.feature.properties.type.toLowerCase()));

              // pan to feature
              this.map.panTo(e.target.getLatLng());

              // emit id on selection service
              this.selectionService.setId(id);

            });
          }
        })
    }
  });

  // style suburb bbox polygons
  private _styleLoc(feat: Feature): PathOptions {
    // @ts-ignore
    if (feat.hasOwnProperty('properties') && feat.properties.hasOwnProperty('buffer')) {
      return {
        weight: 0,
        fillOpacity: 0.5,
        fillColor: '#ccc',
        
      }
    } else {
      return {
        weight: 1,
        dashArray: '10,10',
        fillOpacity: 0,
        color: '#b40000'
      }
    }
    
  }

  private _clearHighlight(): void {

    // clear any existing highlights
    this.providerLayer.eachLayer((layer: Layer) => {
      //@ts-ignore
      layer.setIcon(
        //@ts-ignore
        new mapIcon(layer.feature.properties.type.toLowerCase())
      )
    });
  }

  private _styleProviders(feature: Feature, latlng: LatLng): Marker {
    return marker(latlng, {
      //@ts-ignore
      icon: new mapIcon(feature.properties.type.toLowerCase())
    })
  }


  // convert flat schools json to geojson
  private _providers2geoJSON(providers: Feature[]): FeatureCollection<GeometryObject> {

    // create geojson from flat json
    let geojson: FeatureCollection;

    // create parent
    geojson = {
      type: 'FeatureCollection',
      features: providers.map((prov: any) => { 

        // map point to feature
        let feat: Feature = prov

        // map feature to object
        return feat
       })
    };

    // return object
    return geojson
  }
  
  // initiate + subscribe to getSchools on service
  private _getProviders(params: Params): void {

    this.providersService.getProviders(params)
      .pipe( takeUntil(this._ngUnsubscribe) )
      .subscribe((providers: Feature[]) => {

        // save schools
        this.providers = providers; 

        // clear existing layer
        this.providerLayer.clearLayers();

        // re-add data to existing layer
        this.providerLayer.addData(this._providers2geoJSON(this.providers));

        // refresh clusters (if ready)
        if (this.clusters != undefined) { 
          this.clusters.clearLayers();
          this.clusters.addLayers([this.providerLayer])
        }

        this.providerCount = providers.length;

        this.providersLoading = false;
        
      });
    
  } 

  // reference cluster object when ready
  public onMarkerClusterReady(ev: any): void {
    this.clusters = ev;
  }
  
  public onMapReady(map: Map) {

    this.mapLoading = false;

    // get map obj
    this.map = map;

    // wrap timing issues in empty setTimeout function
    setTimeout(() => {
      // invalidate size on load (gets around layout issues with flex layout)
      // https://github.com/Asymmetrik/ngx-leaflet/issues/223#issuecomment-496237234
      this.map.invalidateSize();

      // set view on map from fragment
      // (gets around timing issue with fragment parsing)
      this.map.setView(this._getMapParams('center') as LatLng, this._getMapParams('zoom') as number)
    });

    // moveend listener
    this.map.on('moveend', () => {
      
      // store zoom and center
      let z: number = this.map.getZoom();
      let c: LatLng = this.map.getCenter();

      // convert to fragment string
      let str: string = `${z},${c.lat},${c.lng}`;

      // send fragment to router
      this.router.navigate(['/'], {
        fragment: str,
        queryParamsHandling: 'merge',
        replaceUrl: false
      }).catch((err) => {
        console.error(`Route fragment error ${err}`);
      });
      // clear map highlights on click
    }).on('click', () => { 
      this._clearHighlight();
      this.selectionService.setId(0);
    })

  }

  // return either the latLng center or the zoom level of the current map from the route fragment
  private _getMapParams(param: string): number | LatLng | undefined {

    // get the fragment and parse it to an object
    let loc: number[] | undefined = this._parseFragment(this.route.snapshot.fragment);
    
    // if asked for center
    if (param == 'center') {

      // if fragment exists and the the values are numbers
      if (loc != undefined  && typeof loc[1] == 'number'  && typeof loc[2] == 'number') return latLng(loc[1], loc[2]);
      // otherwise return the default
      else return this._defCenter
    }
    // if asked for zoom
    if (param == 'zoom') {
      // if fragment exists and the the values are numbers
      if (loc != undefined && typeof loc[0] == 'number') return loc[0];
      // otherwise return the default
      else return this._defZoom;
    }

    // else return undefined
    return undefined
  }

  // parse object from url # fragment
  // this function is naive, just parsing whatever the fragment text is between []
  // this resutls in an array of whatever is there
  // or undefined if the fragment object is null
  private _parseFragment(frag: string): number[] | undefined {

    // if there is no fragment, exit
    if (frag == null) return undefined;
    
    // otherwise return the fragment parsed as an array
    return JSON.parse(`[${frag}]`);
  }

  // function to fit map to either layers, or defautl zoom if no data in layer
  public fitLayers(): void {
    if (this.localityLayer.getLayers().length > 0) {
      this.map.fitBounds(this.localityLayer.getBounds().pad(0.1));
    }
    else if (this.providerCount > 0) {
      this.map.fitBounds(this.providerLayer.getBounds().pad(0.1));
    }
    else this.map.flyTo(latLng(this._defCenter),this._defZoom);
  }

  // compare two objects by serialisaing them and testing if they are equal
  // this works because the stored parameters are copied directly from the read parameters
  // so if there is no change, this should be reliable. If not, it will just return true anyway
  public serialsUnequal(a: any | undefined, b: any | undefined): boolean {
    // if a or b are undefined, return true
    if (a == undefined || b == undefined) return true;
    return JSON.stringify(a) != JSON.stringify(b)
  }

  // clone object
  public clone(obj: any): any {

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        // copy date value
        let copy: Date = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        let copy: any[] = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = this.clone(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      let copy: Object = {};
      for (var attr in obj) {
            //@ts-ignore
            if (obj.hasOwnProperty(attr)) copy[attr] = this.clone(obj[attr]);
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  openProviderDialog(provider: any): void {
    this.providerDialog.open(ProviderDialogComponent, {
      data: provider
    });

  }

  openSnackBar(message: string, action: string, config: MatSnackBarConfig) {
    this._snackBar.open(message, action, config);
  }

  ngOnInit(): void {

    console.debug('map init');

    this._getMapConfig();

    // observe route parameters for filtering
    this.route.queryParamMap
      .pipe( takeUntil(this._ngUnsubscribe) )
      .subscribe(
        {
          next: (params: Params) => {

            // check if route params and stored params are "unequal"
            // return true to trigger a reload, or false to not reload
            let reload: boolean = this.serialsUnequal(this.filterObj, params["params"]);

            // load params (if they exist)
            this.filterObj = Object.keys(params["params"]).length === 0 ? null : params["params"];

            // get providers with params
            if (reload) this._getProviders(this.filterObj);

            // empty data from locality layer
            this.localityLayer.clearLayers();

            // fit map to data, if the map is defined (i.e. only on filter, not initial navigation)
            // this is desired behaviour anyway
            // use reload flag to avoid fitting on url fragment changes (which happen on all map moves)
            if (this.map && reload) {
              // fit to providers layer
              this.fitLayers();
            }

            // if there's a location filter, then do a buffer
            if (this.filterObj !== null && this.filterObj.hasOwnProperty('loc')) {

              let buff: number = 0;
              
              // get buffer radius if it exists
              if (this.filterObj.hasOwnProperty('radius')) {
                buff = this.filterObj.radius;
              }
              
              // if no results, search again with a larger radius
              if (this.providerCount == 0) {

                console.log('No providers within selected suburb and/or radius - increasing search radius...')

                // hold params
                let qParams: Params = {};
                
                // set params
                qParams = this.clone(this.filterObj);

                if (qParams.hasOwnProperty('radius') && parseFloat(qParams.radius) < 20) qParams.radius = parseFloat(qParams.radius) + 5;
                else if (qParams.hasOwnProperty('radius') && parseFloat(qParams.radius) >= 20 && parseFloat(qParams.radius) < 100) qParams.radius = parseFloat(qParams.radius) + 10;
                else if (qParams.hasOwnProperty('radius') && parseFloat(qParams.radius) >= 100 && parseFloat(qParams.radius) < 1500) qParams.radius = parseFloat(qParams.radius) + 100;
                else if (!qParams.hasOwnProperty('radius')) qParams.radius = 5;

                let snackConfig: MatSnackBarConfig = this.mediaObserver.isActive('lt-md') ? this._radiusRespAlertSnackbarConfig : this._radiusLgAlertSnackbarConfig;

                // @ts-ignore
                let snacky: MatSnackBarRef = this.openSnackBar(`Unable to find services in selected suburb, nearest service is within ${qParams.radius} km`, 'Got it', snackConfig);
                
                // send params to router
                this.router.navigate(['/'], {
                  queryParams: qParams,
                  // queryParamsHandling: 'merge',
                  preserveFragment: true
                }).catch((err) => console.error(`Navigation error - ${err}`));

              }
              
              // load locality into map
              // @ts-ignore
              this.localitiesService.getBufferedLocalityById(this.filterObj.loc, buff, false)
                .pipe(takeUntil(this._ngUnsubscribe))
                .subscribe({
                  next: (item: Feature) => {
                    this.localityLayer.addData(item);
                    // only zoom to layer if providers found
                    if (this.providerCount > 0) {
                      this.map.fitBounds(this.localityLayer.getBounds().pad(0.1));
                    }
                    
                },
                  complete: () => { }
                });
              
            }
            
          }
        }
    )

    this.selectionService.getId()
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe({
        next: (val) => {

        // if id is set, scroll to it
          if (val !== null) {

            this.providerLayer.eachLayer((layer: Layer) => {
              // @ts-ignore
              if (layer.feature.properties.id == val) {

                this._clearHighlight();

                // set icon to new highlight icon
                // @ts-ignore
                layer.setIcon(new mapHighlightIcon(layer.feature.properties.type.toLowerCase()));

                // @ts-ignore
                layer.setZIndexOffset(999);

                // pan to feature
                // @ts-ignore
                this.map.panTo(layer.getLatLng());
                

              }
            })
          
          }
      }
    })

  }

  // handle unsubscriptions
  ngOnDestroy() {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

}
