<div class="filter">
	<div fxLayout="row" fxLayoutAlign="space-between center" fxHide.lt-md="true">
		<h1>Filter services</h1>
	</div>
	<div fxHide.lt-md="true">
		<p class="caption">
			The services displayed here are a fraction of those that are included on the 1800 My Options database. For more 
			services, please call <b><a aria-label="Call 1800 My Options on 1800 696 784" href="tel:1800696784">1800 696 784</a> </b> <br> <br>

			 You can search for providers that suit your needs through using the filtering options below, and the postcode 
			 search located on the map. 1800 My Options does not include details about the costs of services. For information 
			 about fees, please contact the service provider.

			<!-- <a fxLayoutAlign="center center" aria-label="Register your service" mat-stroked-button href="https://www.surveymonkey.com/r/NCMKS29" target="blank">Register Your Service</a> -->
		</p>
		
	</div>
	<!-- <p *ngIf="!mediaObserver.isActive('lt-md')">
		What services do you need?
	</p>
	<h2 *ngIf="mediaObserver.isActive('lt-md')">
		What services do you need?
	</h2> -->

	<form name="filter">

		<div *ngFor="let i of filterConfig | keyvalue" class="filter-container">
            <!-- use $any() to access sub-properties. This problem may go away once everything is typed properly. -->
            <!-- Implemented as per: https://stackoverflow.com/a/62139975/13882291 -->
			<div *ngIf="$any(i.value).accordion === false && $any(i.value).type !== 'range' && $any(i.value).show === true">
				<h2>{{ $any(i.value).label }}</h2>
			</div>
			<mat-accordion multi>
				<mat-expansion-panel 
					[id]="i.key"
					(afterExpand)="scrollAccordion(i.key)"
					[expanded]="getAccordionState( i.key, $any(i.value).entries )" 
					(opened)="setAccordionState( i.key )"
					(closed)="setAccordionState( i.key, false)"
					*ngIf="$any(i.value).accordion && $any(i.value).values !== 'multi' && $any(i.value).show">
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ $any(i.value).label }}
						</mat-panel-title>
					</mat-expansion-panel-header>

					<!-- accordion contents -->
					<!-- ng-template + matExpansionPanelContent means lazy loading - no more rendering before we need to!!! -->
					<ng-template matExpansionPanelContent>
						<!-- show list of properties -->
						<div>
							<ul class="filter-ul" >
								<ng-container *ngFor="let opt of $any(i.value).entries | keyvalue | objSort:'sort'" >
									<li fxLayout="row" fxLayoutAlign="space-between center">
										<span>
											<mat-checkbox [checked]="getFilterStatus(opt.key)" (change)="onFormChange($event, opt.key)">{{ $any(opt.value).label }}</mat-checkbox>
										</span>
										<span *ngIf="$any(opt.value).tooltip">
											<mat-icon 
												matTooltip="{{ $any(opt.value).tooltip }}"
												matTooltipPosition="below"
												show="true"
												class="tooltip-icon"
												#tooltip="matTooltip"
												matTooltipClass="tooltip-text">help_outline</mat-icon>
										</span>
									</li>
								</ng-container>
							</ul>
						</div>

						<div *ngIf="i.key == 'abortion'" class="gest-slider">
							<h2>Pregnancy gestation (weeks)</h2>
							<p>Calculated by the number of weeks since last period, if known</p>
							<ngx-slider 
								[(value)]="sliderLo" 
								[(highValue)]="sliderHi" 
								[options]="sliderOptions"
								(userChangeEnd)="sliderChange($event, 'gestation')"
								></ngx-slider>
							<p>Please note that blood tests and/or ultrasound may be required to confirm exact gestation of pregnancy.</p>
						</div>

						<p *ngIf="$any(i.value).link" class="info-link">Need more information about your options? See our 
							<a href="{{ $any(i.value).link }}" target="blank">
								{{ $any(i.value).label }}
							</a> page.
						</p>

					</ng-template>

				</mat-expansion-panel>

				<mat-expansion-panel 
					[id]="i.key"
					(afterExpand)="scrollAccordion(i.key)"
					[expanded]="getFilterStatus(i.key)" 
					*ngIf="$any(i.value).accordion && $any(i.value).values === 'multi' && $any(i.value).show">
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ $any(i.value).label }}
						</mat-panel-title>
					</mat-expansion-panel-header>

					<!-- accordion contents -->
					<!-- ng-template + matExpansionPanelContent means lazy loading - no more rendering before we need to!!! -->
					<ng-template matExpansionPanelContent>
						<!-- show list of properties -->
						<div>
							<ul class="filter-ul">
								<ng-container *ngFor="let opt of $any(i.value).entries | keyvalue | objSort:'sort'" >
									<li>
										<mat-checkbox [checked]="getFilterStatus(opt.key, i.key)" (change)="onFormChange($event, opt.key, i.key)">{{ $any(opt.value).label }}</mat-checkbox>
									</li>
								</ng-container>
							</ul>
						</div>
					</ng-template>

				</mat-expansion-panel>
			</mat-accordion>

		</div> 
	</form>
</div>

